import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/b0d94e48a1009e41b20cb72ccff9dc6d/aa440/Morne_A_stout_man_that_looks_like_Danny_Devito_0e7dc2dd-2c82-46d7-adc1-ff31752d486f.png",
            "srcSet": ["/static/b0d94e48a1009e41b20cb72ccff9dc6d/5ff7e/Morne_A_stout_man_that_looks_like_Danny_Devito_0e7dc2dd-2c82-46d7-adc1-ff31752d486f.png 375w", "/static/b0d94e48a1009e41b20cb72ccff9dc6d/1d69c/Morne_A_stout_man_that_looks_like_Danny_Devito_0e7dc2dd-2c82-46d7-adc1-ff31752d486f.png 750w", "/static/b0d94e48a1009e41b20cb72ccff9dc6d/aa440/Morne_A_stout_man_that_looks_like_Danny_Devito_0e7dc2dd-2c82-46d7-adc1-ff31752d486f.png 1500w", "/static/b0d94e48a1009e41b20cb72ccff9dc6d/71c1d/Morne_A_stout_man_that_looks_like_Danny_Devito_0e7dc2dd-2c82-46d7-adc1-ff31752d486f.png 1536w"],
            "width": "500px",
            "className": "right center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Roz Daras with his Energy Weapon`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p><strong parentName="p">{`Roz Daras`}</strong>{` was a `}<a parentName="p" {...{
        "href": "/Acronist#furybearers",
        "title": "Acronist"
      }}>{`Furybearer`}</a>{` who held up the `}<a parentName="p" {...{
        "href": "/Central%20Bank%20of%20Meripol",
        "title": "Central Bank of Meripol"
      }}>{`Central Bank of Meripol`}</a>{`.`}</p>
    <h2>{`Robbing the Central Bank of Meripol`}</h2>
    <p>{`Daras, with two other criminals, held members and guests of the Central Bank of Meripol in order to break into `}<a parentName="p" {...{
        "href": "/Enzo%20Quint",
        "title": "Enzo Quint"
      }}>{`Enzo Quint's`}</a>{` lockbox. It is currently unknown at this time if Daras was acting of his own accord, or if he was hired by an unknown party.`}</p>
    <p>{`In the heist, Daras carried a weapon, a gun powered by his own `}<a parentName="p" {...{
        "href": "/Primal%20Aspects#energy",
        "title": "Primal Aspects"
      }}>{`Energy`}</a>{`.`}</p>
    <p>{`He was eventually thwarted by `}<a parentName="p" {...{
        "href": "/Lucian%20Cullpepper",
        "title": "Lucian Cullpepper"
      }}>{`Lucian Cullpepper`}</a>{`, `}<a parentName="p" {...{
        "href": "/Eileen%20Quint",
        "title": "Eileen Quint"
      }}>{`Eileen Quint`}</a>{`, and `}<a parentName="p" {...{
        "href": "/Sulazar%20Stillwater",
        "title": "Sulazar Stillwater"
      }}>{`Sulazar Stillwater`}</a>{`, bashed over the head by a piece of rebar by Sulazar Stillwater. After, Daras was taken into custody by the `}<a parentName="p" {...{
        "href": "/Vigil",
        "title": "Vigil"
      }}>{`Vigil`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      